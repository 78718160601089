<template>
  <div>
    <v-container
      v-if="bShowRankings"
      style="margin: 0px !important; padding-bottom: 40px; overflow: hidden"
      fluid
      wrap
      class="content-rankins"
      :class="sClassDynamicContentRankin"
    >
      <v-layout row wrap>
        <!-- #region Precios promedio de compra y venta (MXN) -->
        <v-flex style="padding-right: 10px" xs12 sm6 md6>
          <div
            :style="$vuetify.breakpoint.smAndUp ? '' : 'margin-top: 10px'"
            class="content-card-all"
          >
            <p class="title-graphic-static">
              Precios promedio de compra y venta(MXN)
            </p>
            <div class="content-rankins-card">
              <div>
                <div class="content-card-body">
                  <div class="content-name">
                    <span :class="'txt-value-first'"> Materia prima </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="'txt-value-first'"
                    >
                      Compra
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="'txt-value-first'"
                    >
                      Venta
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <v-divider class="divider-global"> </v-divider>
              </div>
              <div
                v-for="(item, i) in aRankins.find((e) => e.iIdRankins === 5)
                  .aRankinMXN"
                :key="i"
              >
                <div class="content-card-body">
                  <div class="content-name">
                    <span :class="i === 0 ? 'txt-value-first' : 'txt-value'">
                      {{ item.sName }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      $
                      {{
                        item.dMexicanPesoAverageSell == null
                          ? 0.0
                          : formatMoneyGlobal(item.dMexicanPesoAverageBuy) +
                            "MXN"
                      }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      {{
                        item.dMexicanPesoAverageSell == null
                          ? 0.0
                          : formatMoneyGlobal(item.dMexicanPesoAverageSell) +
                            " MXN"
                      }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <v-divider class="divider-global"> </v-divider>
              </div>
              <div
                v-if="
                  aRankins.find((e) => e.iIdRankins === 5).aRankinMXN.length ===
                  0
                "
                class="content-rankins-provider-empty"
              >
                <p class="text-data-empty">Sin informacion</p>
              </div>
            </div>
          </div>
        </v-flex>
        <!-- #endregion Precios promedio de compra y venta (MXN) -->

        <!-- #region Precios promedio de compra y venta (USD) -->
        <v-flex style="padding-right: 10px" xs12 sm6 md6>
          <div
            :style="$vuetify.breakpoint.smAndUp ? '' : 'margin-top: 10px'"
            class="content-card-all"
          >
            <p class="title-graphic-static">
              Precios promedio de compra y venta(USD)
            </p>
            <div class="content-rankins-card">
              <div>
                <div class="content-card-body">
                  <div class="content-name">
                    <span :class="'txt-value-first'"> Materia prima </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="'txt-value-first'"
                    >
                      Compra
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="'txt-value-first'"
                    >
                      Venta
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <v-divider class="divider-global"> </v-divider>
              </div>
              <div
                v-for="(item, i) in aRankins.find((e) => e.iIdRankins === 5)
                  .aRankinUSD"
                :key="i"
              >
                <div class="content-card-body">
                  <div class="content-name">
                    <span :class="i === 0 ? 'txt-value-first' : 'txt-value'">
                      {{ item.sName }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      $
                      {{
                        item.dUSDollarAverageBuy == null
                          ? 0.0
                          : formatMoneyGlobal(item.dUSDollarAverageBuy) + " USD"
                      }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      {{
                        item.dUSDollarAverageSell == null
                          ? 0.0
                          : formatMoneyGlobal(item.dUSDollarAverageSell) + "USD"
                      }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <v-divider class="divider-global"> </v-divider>
              </div>
              <div
                v-if="
                  aRankins.find((e) => e.iIdRankins === 5).aRankinUSD.length ===
                  0
                "
                class="content-rankins-provider-empty"
              >
                <p class="text-data-empty">Sin información</p>
              </div>
            </div>
          </div>
        </v-flex>
        <!-- #endregion Precios promedio de compra y venta (USD) -->

        <v-flex style="padding-right: 22px; padding-top: 13px">
          <v-layout row wrap>
            <!--#region Start data: "Proveedores con más operaciones" -->
            <v-flex style="padding-left: 13px" xs12 sm6 md6>
              <div class="content-card-all">
                <p class="title-graphic-static">
                  Proveedores con más operaciones
                </p>
                <div class="content-rankins-card">
                  <div
                    v-for="(item, i) in aRankinsProviderWithMoreOperations"
                    :key="i"
                  >
                    <div class="content-card-body">
                      <div class="content-id">
                        <span
                          style="margin-right: 10px; margin-left: 10px"
                          :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                        >
                          {{ i + 1 }}
                        </span>
                      </div>
                      <div class="content-name">
                        <span
                          :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                        >
                          {{ item.sBussinessName }}
                        </span>
                      </div>
                      <v-spacer></v-spacer>
                      <div class="content-value-quantity">
                        <span
                          style="padding-right: 20px"
                          :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                        >
                          {{ item.dAmount }}
                        </span>
                      </div>
                    </div>
                    <v-divider class="divider-global"> </v-divider>
                  </div>
                  <div
                    v-if="aRankinsProviderWithMoreOperations.length === 0"
                    class="content-rankins-provider-empty"
                  >
                    <p class="text-data-empty">Sin informacion</p>
                  </div>
                </div>
              </div>
            </v-flex>
            <!--#endregion Start data: "Proveedores con más operaciones" -->

            <!-- #region Start data: "Clientes con más operaciones" -->
            <v-flex style="padding-left: 13px" xs12 sm6 md6>
              <div class="content-card-all">
                <p class="title-graphic-static">Clientes con más operaciones</p>
                <div class="content-rankins-card">
                  <div
                    v-for="(item, i) in aRankinsClientWithMoreOperations"
                    :key="i"
                  >
                    <div class="content-card-body">
                      <div class="content-id">
                        <span
                          style="margin-right: 10px; margin-left: 10px"
                          :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                        >
                          {{ i + 1 }}
                        </span>
                      </div>
                      <div class="content-name">
                        <span
                          :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                        >
                          {{ item.sBusinessName }}
                        </span>
                      </div>
                      <v-spacer></v-spacer>
                      <div class="content-value-quantity">
                        <span
                          style="padding-right: 20px"
                          :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                        >
                          {{ item.dAmount }}
                        </span>
                      </div>
                    </div>
                    <v-divider class="divider-global"> </v-divider>
                  </div>
                  <div
                    v-if="aRankinsClientWithMoreOperations.length === 0"
                    class="content-rankins-provider-empty"
                  >
                    <p class="text-data-empty">Sin informacion</p>
                  </div>
                </div>
              </div>
            </v-flex>
            <!-- #endregion Start data: "Clientes con más operaciones" -->
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <!-- #region Start data: "Proveedores con más impacto(MXN)" -->
        <v-flex style="padding-right: 10px" xs12 sm6 md6 class="mt-3">
          <div class="content-card-all">
            <p class="title-graphic-static">
              Proveedores con más impacto (MXN)
            </p>
            <div class="content-rankins-card">
              <div v-for="(item, i) in aRankinsProviderWithMoreImpact" :key="i">
                <div class="content-card-body">
                  <div class="content-id">
                    <span
                      style="margin-right: 10px; margin-left: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      {{ i + 1 }}
                    </span>
                  </div>
                  <div
                    :style="$vuetify.breakpoint.smAndUp ? '' : 'max-width: 30%'"
                    class="content-name"
                  >
                    <span :class="i === 0 ? 'txt-value-first' : 'txt-value'">
                      {{ item.sBussinessName }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      $
                      {{ formatMoneyGlobal(item.dMexicanPesoAmount) + " MXN" }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <v-divider class="divider-global"> </v-divider>
              </div>
              <div
                v-if="aRankinsProviderWithMoreImpact.length === 0"
                class="content-rankins-provider-empty"
              >
                <p class="text-data-empty">Sin informacion</p>
              </div>
            </div>
          </div>
        </v-flex>
        <!-- #endregion Start data: "Proveedores con más impacto(MXN)" -->

        <!-- #region Start data: "Proveedores con más impacto(USD)" -->
        <v-flex style="padding-right: 10px" xs12 sm6 md6 class="mt-3">
          <div class="content-card-all">
            <p class="title-graphic-static">
              Proveedores con más impacto (USD)
            </p>
            <div class="content-rankins-card">
              <div
                v-for="(item, i) in aRankinsProviderWithMoreImpactUSD"
                :key="i"
              >
                <div class="content-card-body">
                  <div class="content-id">
                    <span
                      style="margin-right: 10px; margin-left: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      {{ i + 1 }}
                    </span>
                  </div>
                  <div
                    :style="$vuetify.breakpoint.smAndUp ? '' : 'max-width: 30%'"
                    class="content-name"
                  >
                    <span :class="i === 0 ? 'txt-value-first' : 'txt-value'">
                      {{ item.sBussinessName }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      $ {{ formatMoneyGlobal(item.dUSDollarAmount) + " USD" }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <v-divider class="divider-global"> </v-divider>
              </div>
              <div
                v-if="aRankinsProviderWithMoreImpactUSD.length === 0"
                class="content-rankins-provider-empty"
              >
                <p class="text-data-empty">Sin informacion</p>
              </div>
            </div>
          </div>
        </v-flex>
        <!-- #endregion Start data: "Proveedores con más impacto(USD)" -->

        <!-- #region Start data: "Clientes con más impacto(MXN)" -->
        <v-flex style="padding-right: 10px" xs12 sm6 md6>
          <div class="content-card-all">
            <p class="title-graphic-static">Clientes con más impacto(MXN)</p>
            <div class="content-rankins-card">
              <div v-for="(item, i) in aRankinsClientWithMoreImpact" :key="i">
                <div class="content-card-body">
                  <div class="content-id">
                    <span
                      style="margin-right: 10px; margin-left: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      {{ i + 1 }}
                    </span>
                  </div>
                  <div
                    :style="$vuetify.breakpoint.smAndUp ? '' : 'max-width: 30%'"
                    class="content-name"
                  >
                    <span :class="i === 0 ? 'txt-value-first' : 'txt-value'">
                      {{ item.sBusinessName }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      $
                      {{ formatMoneyGlobal(item.dMexicanPesoAmount) + " MXN" }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <v-divider class="divider-global"> </v-divider>
              </div>
              <div
                v-if="aRankinsClientWithMoreImpact.length === 0"
                class="content-rankins-provider-empty"
              >
                <p class="text-data-empty">Sin información.</p>
              </div>
            </div>
          </div>
        </v-flex>
        <!-- #endregion Start data: "Clientes con más impacto(MXN)" -->

        <!-- #region Start data: "Clientes con más impacto(USD)" -->
        <v-flex style="padding-right: 10px" xs12 sm6 md6>
          <div class="content-card-all">
            <p class="title-graphic-static">Clientes con más impacto(USD)</p>
            <div class="content-rankins-card">
              <div v-for="(item, i) in aRankinsClientWithMoreImpactUSD" :key="i">
                <div class="content-card-body">
                  <div class="content-id">
                    <span
                      style="margin-right: 10px; margin-left: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      {{ i + 1 }}
                    </span>
                  </div>
                  <div
                    :style="$vuetify.breakpoint.smAndUp ? '' : 'max-width: 30%'"
                    class="content-name"
                  >
                    <span :class="i === 0 ? 'txt-value-first' : 'txt-value'">
                      {{ item.sBusinessName }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="content-value-quantity">
                    <span
                      style="padding-right: 10px"
                      :class="i === 0 ? 'txt-value-first' : 'txt-value'"
                    >
                      $ {{ formatMoneyGlobal(item.dUSDollarAmount) + " USD" }}
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <v-divider class="divider-global"> </v-divider>
              </div>
              <div
                v-if="aRankinsClientWithMoreImpactUSD.length === 0"
                class="content-rankins-provider-empty"
              >
                <p class="text-data-empty">Sin información.</p>
              </div>
            </div>
          </div>
        </v-flex>
        <!-- #endregion Start data: "Clientes con más impacto(USD)" -->
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    aRankins: Array,
  },
  data() {
    return {
      aRankinsProviderWithMoreOperations: [],
      aRankinsClientWithMoreOperations: [],

      aRankinsProviderWithMoreOperationsUSD: [],
      aRankinsClientWithMoreOperationsUSD: [],

      aRankinsProviderWithMoreImpact: [],
      aRankinsClientWithMoreImpact: [],

      aRankinsProviderWithMoreImpactUSD: [],
      aRankinsClientWithMoreImpactUSD: [],

      sClassDynamicContentRankin: "",
      sClassDynamicContentRankinProvider: "",
      sClassDynamicContentRankinClient: "",
      bShowRankings: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // Esta validacion esta ajustada a 4 contenidos
      if (window.innerWidth > 959.99 && window.innerWidth < 1350) {
        this.sClassDynamicContentRankin = "";
        // this.sClassDynamicAmountCard = "";
        if (this.bSidebar) {
          // Clase dinamica activada con sidebar abierto
          this.sClassDynamicContentRankin =
            "dynamic-class-open-sidebar-content-rankins";
        } else {
          if (window.innerWidth > 959.99 && window.innerWidth < 1130) {
            // Clase dinamica activada con sidebar cerrado
            this.sClassDynamicContentRankin =
              "dynamic-class-close-sidebar-content-rankins";
          }
        }
      } else {
        // Clase dinamica desactivada
        this.sClassDynamicContentRankin = "";
      }
    },
    setFillData() {
      if (this.aRankins.length > 0) {
        this.aRankinsProviderWithMoreOperations = this.aRankins.find(
          (e) => e.iIdRankins === 1
        ).aRankinMXN;
        this.aRankinsProviderWithMoreOperationsUSD = this.aRankins.find(
          (e) => e.iIdRankins === 1
        ).aRankinUSD;

        this.aRankinsClientWithMoreOperations = this.aRankins.find(
          (e) => e.iIdRankins === 3
        ).aRankinMXN;
        this.aRankinsClientWithMoreOperationsUSD = this.aRankins.find(
          (e) => e.iIdRankins === 3
        ).aRankinUSD;

        this.aRankinsProviderWithMoreImpact = this.aRankins.find(
          (e) => e.iIdRankins === 2
        ).aRankinMXN;
        this.aRankinsProviderWithMoreImpactUSD = this.aRankins.find(
          (e) => e.iIdRankins === 2
        ).aRankinUSD;

        this.aRankinsClientWithMoreImpact = this.aRankins.find(
          (e) => e.iIdRankins === 4
        ).aRankinMXN;
        this.aRankinsClientWithMoreImpactUSD = this.aRankins.find(
          (e) => e.iIdRankins === 4
        ).aRankinUSD;

        this.bShowRankings = true;
      } else {
        this.bShowRankings = false;
      }
    },
  },
  computed: {
    // bShowRankings() {
    //     return this.aRankins[0].aRankin == null ? false : true
    // },
    bSidebar() {
      return this.$store.state.bSidebar;
    },
  },
  watch: {
    bSidebar() {
      this.handleResize();
    },
    aRankins() {
      this.setFillData();
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 800px) {
  .content-name {
  }
}

.content-rankins {
  display: block;
  overflow: auto;
}

.dynamic-class-open-sidebar-content-rankins {
  display: block !important;
  margin-bottom: 15px;
}

.dynamic-class-close-sidebar-content-rankins {
  display: block !important;
  margin-bottom: 15px;
}

.content-rankins-provider {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}

.content-rankins-client {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}

.title-graphic {
  font-family: "pop-Semibold";
  font-size: 13px;
  color: var(--primary-color-color-title-information);
}

.title-graphic-static {
  font-family: "pop-Semibold";
  font-size: 13px;
  color: var(--primary-color-color-title-information);
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 6px;
}

.content-card-all {
  width: 100%;
}

.content-rankins-card {
  /* max-height: 516px;
                height: 516px;
                overflow-y: scroll;
                overflow-x: hidden; */
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 240px;
  border-radius: 10px;
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
}
.content-rankins-card::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.content-rankins-card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-rankins-card::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-rankins-card::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-card-body {
  display: flex;
  padding: 10px 10px 10px 10px;
}

.content-id {
  margin-right: 5px;
}

.content-name {
  width: 45%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  color: var(--primary-color-text) !important;
}

.content-value {
  width: 55%;
  max-width: 47%;
  text-align: end;
  margin-left: 5px;
  white-space: nowrap;
}

.content-value-quantity {
  width: 55%;
  max-width: 10%;
  text-align: end;
  margin-left: 5px;
  white-space: nowrap;
}

.content-value-money {
  width: 55%;
  max-width: 47%;
  text-align: end;
  margin-left: 5px;
  white-space: nowrap;
}

.txt-value-first {
  color: var(--primary-color-text);
  font-family: "pop-Semibold";
  font-size: 13px;
}

.txt-value {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 13px;
}

.content-rankins-provider-empty {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-rankins {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
  }

  .content-rankins-card {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: auto;
    border-radius: 10px;
    background-color: var(--primary-color-background-table) !important;
    margin-bottom: 10px;
  }
  .content-rankins-card::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  .content-rankins-card::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  .content-rankins-card::-webkit-scrollbar-thumb {
    background: var(--primary-color-scroll);

    border-radius: 10px;
  }

  .content-rankins-card::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color-scroll);
  }

  .content-rankins-provider {
    display: block;
  }

  .content-rankins-client {
    display: block;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-rankins {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
  }

  .content-rankins-card {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: auto;
    border-radius: 10px;
    background-color: var(--primary-color-background-table) !important;
    margin-bottom: 10px;
  }
  .content-rankins-card::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  .content-rankins-card::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  .content-rankins-card::-webkit-scrollbar-thumb {
    background: var(--primary-color-scroll);

    border-radius: 10px;
  }

  .content-rankins-card::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color-scroll);
  }

  .content-rankins-provider {
    display: block;
  }

  .content-rankins-client {
    display: block;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
